<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal wrap">
      <InvestmentItem
        v-for="investment in getInvestments"
        :key="investment._id"
        :investment="investment"
      />
      <ModalViewInvestment />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvestmentItem from "./components/InvestmentItem";
import ModalViewInvestment from "./components/modalViewInvestment";
export default {
  data() {
    return {
      options:[
        {
          nome: 'Cadastrar Ativo',
          action: () => this.abreModalViewInvestment({})
        }
      ]
    }
  },
  components: {
    InvestmentItem,
    ModalViewInvestment
  },
  computed: {
    ...mapGetters(["getInvestments"])
  },
  methods: {
    ...mapActions(["listInvestments", "abreModalViewInvestment", "listSystemActions"])
  },
  created() {
    this.listInvestments();
    this.listSystemActions();
  }
};
</script>
